import React from "react"
import { Box } from "grommet"
import { Link, Layout, SEO } from "../components"

const Page = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Box pad="small">
        <Link color="red" to="/boilerplates/nav" label="test">
          Go to boilerplate nav page
        </Link>
      </Box>
      <Box pad="small">
        <Link color="red" to="/boilerplates" label="test">
          Go to boilerplate index page
        </Link>
      </Box>
      <Box pad="small">
        <Link color="red" to="/sites">
          Go to sites
        </Link>
      </Box>
      <Box pad="small">
        <Link color="red" to="/new">
          Create new site
        </Link>
      </Box>
      <Box pad="small">
        <Link color="red" to="/connections">
          Go to conections
        </Link>
      </Box>
      <Box pad="small">
        <Link
          color="red"
          to="/import/site?url=https://github.com/theowenyoung/gatsby-starter-lumen"
        >
          Import from github
        </Link>
      </Box>
    </Layout>
  )
}

export default Page
